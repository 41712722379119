<template>

  <div v-if="!loading && invoiceExists" class="max-w-pdf w-full bg-white rounded-lg">
    <div>

      <div class="flex gap-10">

        <div class="w-6/12 flex flex-col">
          <Logo
            :companyLogo="logo"
          />
          <From
            :name="fromName"
            :address="fromAddress"
          />
        </div>

        <div class="w-6/12 flex flex-col">
          <Title
            :title="title"
            :serial="serial"
          />

          <PaymentInfo
            :accentColor="accentColor"
            :accentTextColor="accentTextColor"
            :invoiceDateLabel="invoiceDateLabel"
            :invoiceDate="invoiceDate"
            :paymentDateLabel="paymentDateLabel"
            :paymentDate="paymentDate"
            :paymentTermsLabel="paymentTermsLabel"
            :paymentTerms="paymentTerms"
            :balanceDueLabel="balanceDueLabel"
            :formattedDueAmount="formattedDueAmount"
            :currency="currency"
            :currencyLabel="currencyLabel"
          />
        </div>

      </div>

      <div class="flex gap-20 mt-6">
        <To
          v-if="toLabel || toName || toAddress"
          :accentTextColor="accentTextColor"
          :toLabel="toLabel"
          :toName="toName"
          :toAddress="toAddress"
        />

        <ShipTo
          :accentTextColor="accentTextColor"
          :shipToLabel="shipToLabel"
          :shipToName="shipToName"
          :shipToAddress="shipToAddress"
        />
      </div>

      <div class="mt-10">
        <Items
          v-if="currency"
          :accentColor="accentColor"
          :currency="currency"
          :itemLabel="itemLabel"
          :quantityLabel="quantityLabel"
          :rateLabel="rateLabel"
          :amountLabel="amountLabel"
          :itemRows="itemRows"
        />
      </div>

      <div class="py-2" style="break-inside: avoid;">
        <div class="flex gap-5 mt-10">
          <div class="flex flex-col w-7/12">
            <NotesAndTerms
              :notesLabel="notesLabel"
              :notesText="notesText"
              :termsLabel="termsLabel"
              :termsText="termsText"
            />
          </div>

          <div class="flex flex-col w-5/12">
            <Totals
              :accentTextColor="accentTextColor"
              :currency="currency"
              :subtotal="subtotal"
              :accentColor="accentColor"
              :subtotalLabel="subtotalLabel"
              :discountLabel="discountLabel"
              :discount="discount"
              :discountType="discountType"
              :taxLabel="taxLabel"
              :tax="tax"
              :taxType="taxType"
              :shippingCostLabel="shippingCostLabel"
              :shippingCost="shippingCost"
              :amountPaidLabel="amountPaidLabel"
              :amountPaid="amountPaid"
              :totalLabel="totalLabel"
              :total="total"
              :discountCurrToPerc="discountCurrToPerc"
              :discountPercToCurr="discountPercToCurr"
              :taxCurrToPerc="taxCurrToPerc"
              :taxPercToCurr="taxPercToCurr"
            />
          </div>
        </div>
      </div>

    </div>
  </div>

  <div
    v-else-if="!loading && !invoiceExists"
    class="flex justify-center items-center max-w-pdf w-full h-screen bg-white rounded-lg"
  >
    <div class="text-il-purple text-2xl uppercase">
      Invoice does not exist
    </div>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex';
  import quickInvoiceRequests from '../../helpers/quickInvoiceRequests';
  import clientInvoiceRequests from '../../helpers/clientInvoiceRequests';
  import lodash from 'lodash';

  import Logo from '../../components/InvoicePreview/Logo'
  import From from '../../components/InvoicePreview/From';
  import Title from '../../components/InvoicePreview/Title';
  import PaymentInfo from '../../components/InvoicePreview/PaymentInfo';
  import To from '../../components/InvoicePreview/To';
  import ShipTo from '../../components/InvoicePreview/ShipTo';
  import Items from '../../components/InvoicePreview/Items';
  import NotesAndTerms from '../../components/InvoicePreview/NotesAndTerms';
  import Totals from '../../components/InvoicePreview/Totals';

  export default {
    data() {
      return {
        loading: true,
        invoiceExists: false,
        invoiceUUID: this.$route.params.invoice_uuid,
        companyUUID: this.$route.params.company_uuid,
        clientUUID: this.$route.params.client_uuid,
        seenHash: this.$route.params.seen_hash,
        accentColor: null,
        accentTextColor: null,
        logo: null,
        fromName: null,
        fromAddress: null,
        title: null,
        serial: null,
        invoiceDateLabel: null,
        invoiceDate: null,
        paymentDateLabel: null,
        paymentDate: null,
        paymentTermsLabel: null,
        paymentTerms: null,
        balanceDueLabel: null,
        formattedDueAmount: null,
        currency: null,
        currencyLabel: null,
        toLabel: null,
        toName: null,
        toAddress: null,
        shipToLabel: null,
        shipToName: null,
        shipToAddress: null,
        itemLabel: null,
        quantityLabel: null,
        rateLabel: null,
        amountLabel: null,
        itemRows: null,
        notesLabel: null,
        notesText: null,
        termsLabel: null,
        termsText: null,
        subtotalLabel: null,
        subtotal: null,
        discountLabel: null,
        discount: null,
        discountType: null,
        taxLabel: null,
        tax: null,
        taxType: null,
        shippingCostLabel: null,
        shippingCost: null,
        amountPaidLabel: null,
        amountPaid: null,
        totalLabel: null,
        total: null,
        discountCurrToPerc: null,
        discountPercToCurr: null,
        taxCurrToPerc: null,
        taxPercToCurr: null
      }
    },
    components: {
      Logo,
      From,
      Title,
      PaymentInfo,
      To,
      ShipTo,
      Items,
      NotesAndTerms,
      Totals
    },
    mounted() {
      if(this.companyUUID && this.clientUUID && this.invoiceUUID) {
        clientInvoiceRequests.getInvoiceDetails(
          this.invoiceUUID,
          this.companyUUID,
          this.clientUUID
        )
        .then((response) => {
          this.accentColor = response.data.invoice_details.accent_color;
          this.accentTextColor = response.data.invoice_details.accent_text_color;
          this.logo = response.data.invoice_details.from_logo_url;
          this.fromName = response.data.invoice_details.from_name;
          this.fromAddress = response.data.invoice_details.from_address;
          this.title = response.data.invoice_details.invoice_title;
          this.serial = response.data.invoice_details.invoice_serial;
          this.invoiceDateLabel = response.data.invoice_details.invoice_date_label;
          this.invoiceDate = response.data.invoice_details.invoice_date;
          this.paymentDateLabel = response.data.invoice_details.payment_date_label;
          this.paymentDate = response.data.invoice_details.payment_date;
          this.paymentTermsLabel = response.data.invoice_details.payment_terms_label;
          this.paymentTerms = response.data.invoice_details.payment_terms;
          this.balanceDueLabel = response.data.invoice_details.balance_due_label;
          this.formattedDueAmount = response.data.invoice_details.balance_due;

          const currency = lodash.find(this.getCurrenciesListX, function(c) { return c.id == response.data.invoice_details.currency_id; });
          this.currency = currency;

          this.currencyLabel = response.data.invoice_details.currency_label;
          this.toLabel = response.data.invoice_details.bill_to_label;
          this.toName = response.data.invoice_details.bill_to_name;
          this.toAddress = response.data.invoice_details.bill_to_address;
          this.shipToLabel = response.data.invoice_details.ship_to_label;
          this.shipToName = response.data.invoice_details.ship_to_name;
          this.shipToAddress = response.data.invoice_details.ship_to_address;
          this.itemLabel = response.data.invoice_details.item_label;
          this.quantityLabel = response.data.invoice_details.quantity_label;
          this.rateLabel = response.data.invoice_details.rate_label;
          this.amountLabel = response.data.invoice_details.amount_label;
          this.itemRows = response.data.invoice_details.client_invoice_items;
          this.notesLabel = response.data.invoice_details.notes_label;
          this.notesText = response.data.invoice_details.notes;
          this.termsLabel = response.data.invoice_details.terms_label;
          this.termsText = response.data.invoice_details.terms;
          this.subtotalLabel = response.data.invoice_details.subtotal_label;
          this.subtotal = response.data.invoice_details.subtotal;
          this.discountLabel = response.data.invoice_details.discount_label;
          this.discount = response.data.invoice_details.discount;
          this.discountType = response.data.invoice_details.discount_type;
          this.taxLabel = response.data.invoice_details.tax_label;
          this.tax = response.data.invoice_details.tax;
          this.taxType = response.data.invoice_details.tax_type;
          this.shippingCostLabel = response.data.invoice_details.shipping_cost_label;
          this.shippingCost = response.data.invoice_details.shipping_cost;
          this.amountPaidLabel = response.data.invoice_details.amount_paid_label;
          this.amountPaid = response.data.invoice_details.amount_paid;
          this.totalLabel = response.data.invoice_details.total_label;
          this.total = response.data.invoice_details.total;
          this.discountCurrToPerc = response.data.invoice_details.discount_curr_to_perc;
          this.discountPercToCurr = response.data.invoice_details.discount_perc_to_curr;
          this.taxCurrToPerc = response.data.invoice_details.tax_curr_to_perc;
          this.taxPercToCurr = response.data.invoice_details.tax_perc_to_curr;

          this.loading = false;
          this.invoiceExists = true;

          if(this.seenHash) { // Set as Seen
            clientInvoiceRequests.markSeen(response.data.invoice_details.id)
            .then((response) => {

            }).catch(error => {
              console.log(error);
            });
          }
        }).catch(error => {
          // console.log(error);
          this.loading = false;
        });
      } else {
        quickInvoiceRequests.getInvoiceDetails(this.invoiceUUID)
        .then((response) => {
          this.accentColor = response.data.invoice_details.accent_color;
          this.accentTextColor = response.data.invoice_details.accent_text_color;
          this.logo = response.data.invoice_details.from_logo_url;
          this.fromName = response.data.invoice_details.from_name;
          this.fromAddress = response.data.invoice_details.from_address;
          this.title = response.data.invoice_details.invoice_title;
          this.serial = response.data.invoice_details.invoice_serial;
          this.invoiceDateLabel = response.data.invoice_details.invoice_date_label;
          this.invoiceDate = response.data.invoice_details.invoice_date;
          this.paymentDateLabel = response.data.invoice_details.payment_date_label;
          this.paymentDate = response.data.invoice_details.payment_date;
          this.paymentTermsLabel = response.data.invoice_details.payment_terms_label;
          this.paymentTerms = response.data.invoice_details.payment_terms;
          this.balanceDueLabel = response.data.invoice_details.balance_due_label;
          this.formattedDueAmount = response.data.invoice_details.balance_due;

          const currency = lodash.find(this.getCurrenciesListX, function(c) { return c.id == response.data.invoice_details.currency_id; });
          this.currency = currency;

          this.currencyLabel = response.data.invoice_details.currency_label;
          this.toLabel = response.data.invoice_details.bill_to_label;
          this.toName = response.data.invoice_details.bill_to_name;
          this.toAddress = response.data.invoice_details.bill_to_address;
          this.shipToLabel = response.data.invoice_details.ship_to_label;
          this.shipToName = response.data.invoice_details.ship_to_name;
          this.shipToAddress = response.data.invoice_details.ship_to_address;
          this.itemLabel = response.data.invoice_details.item_label;
          this.quantityLabel = response.data.invoice_details.quantity_label;
          this.rateLabel = response.data.invoice_details.rate_label;
          this.amountLabel = response.data.invoice_details.amount_label;
          this.itemRows = response.data.invoice_details.quick_invoice_items;
          this.notesLabel = response.data.invoice_details.notes_label;
          this.notesText = response.data.invoice_details.notes;
          this.termsLabel = response.data.invoice_details.terms_label;
          this.termsText = response.data.invoice_details.terms;
          this.subtotalLabel = response.data.invoice_details.subtotal_label;
          this.subtotal = response.data.invoice_details.subtotal;
          this.discountLabel = response.data.invoice_details.discount_label;
          this.discount = response.data.invoice_details.discount;
          this.discountType = response.data.invoice_details.discount_type;
          this.taxLabel = response.data.invoice_details.tax_label;
          this.tax = response.data.invoice_details.tax;
          this.taxType = response.data.invoice_details.tax_type;
          this.shippingCostLabel = response.data.invoice_details.shipping_cost_label;
          this.shippingCost = response.data.invoice_details.shipping_cost;
          this.amountPaidLabel = response.data.invoice_details.amount_paid_label;
          this.amountPaid = response.data.invoice_details.amount_paid;
          this.totalLabel = response.data.invoice_details.total_label;
          this.total = response.data.invoice_details.total;
          this.discountCurrToPerc = response.data.invoice_details.discount_curr_to_perc;
          this.discountPercToCurr = response.data.invoice_details.discount_perc_to_curr;
          this.taxCurrToPerc = response.data.invoice_details.tax_curr_to_perc;
          this.taxPercToCurr = response.data.invoice_details.tax_perc_to_curr;

          this.loading = false;
          this.invoiceExists = true;
        }).catch(error => {
          // console.log(error);
          this.loading = false;
        });
      }
    },
    computed: {
      ...mapGetters([
        'getCurrenciesListX'
      ])
    }
  }
</script>