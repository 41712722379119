import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const clientInvoiceUrl = store.state.apiUrl+'client-invoice/';

export default {
  createOrUpdate(
    invoiceUUID,
    clientUUID,
    invoiceData
  ) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + 'create-update',
      data: {
        invoice_data: invoiceData,
        invoice_uuid: invoiceUUID,
        client_uuid: clientUUID
      }
    });
  },
  downloadPDF(
    invoiceUUID,
    companyUUID,
    clientUUID
  ) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + 'generate',
      data: {
        'invoice_uuid': invoiceUUID,
        'company_uuid': companyUUID,
        'client_uuid': clientUUID,
      },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    });
  },
  getInvoiceDetails(
    invoiceUUID,
    companyUUID,
    clientUUID
  ) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + 'details',
      data: {
        'invoice_uuid': invoiceUUID,
        'company_uuid': companyUUID,
        'client_uuid': clientUUID,
      },
    });
  },
  deleteInvoice(
    invoiceUUID,
    companyUUID,
    clientUUID
  ) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + 'delete',
      data: {
        'invoice_uuid': invoiceUUID,
        'company_uuid': companyUUID,
        'client_uuid': clientUUID,
      }
    });
  },
  markSeen(invoiceId) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + invoiceId + '/mark/seen'
    });
  },
  markSent(invoiceId,email,name,emailMessage,invoiceLinkMessage) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + invoiceId + '/mark/sent',
      data: {
        email: email,
        name: name,
        email_message: emailMessage,
        invoice_link_message: invoiceLinkMessage
      }
    });
  },
  markPaid(invoiceId,email) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + invoiceId + '/mark/paid'
    });
  },
  sendInvoice(invoiceId,email,name,emailMessage,invoiceLinkMessage) {
    return axios({
      method: 'post',
      url: clientInvoiceUrl + invoiceId + '/send',
      data: {
        email: email,
        name: name,
        email_message: emailMessage,
        invoice_link_message: invoiceLinkMessage
      }
    });
  }







  // getInvoiceDetails(invoiceUUID) {
  //   return axios({
  //     method: 'post',
  //     url: quickInvoiceUrl + 'details',
  //     data: {
  //       'invoice_uuid': invoiceUUID,
  //     },
  //   });
  // },

  // deleteInvoice(invoiceUUID) {
  //   return axios({
  //     method: 'post',
  //     url: quickInvoiceUrl + 'delete',
  //     data: {
  //       'invoice_uuid': invoiceUUID,
  //     }
  //   });
  // },
}